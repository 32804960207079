
import ServicesItems from '@/modules/services/components/services-items.vue';
import { mapActions, mapGetters } from 'vuex';
// import { i18n } from '@/i18n';
import i18n from "@/vueI18n";

export default {
components:{
        [ServicesItems.name]: ServicesItems,
    },
    methods:{
      // openLoginDialog(){
      //   console.log('open login')
      // },
      i18n(key, args) {
        return this.$t(key, args);
      },
      ...mapActions({
          setHeaderBg:'layout/setHeaderBg',
          setNavbar:'layout/setNavbar',
          setFooter:'layout/setFooter'
      
      }),
       
    //     scrollHandler(scroll){
    //   if(scroll.position == 0 )  {
    //     this.setHeaderBg('bg-transparent')
    //   }
    //   else{
    //     this.setHeaderBg('bg-shadow animate__animated animate__fadeInDown')
    //   }
    // }
    }, 
    watch:{
      lang(){
        document.title =
          this.i18n('app.title') +
          ' | ' +
          this.i18n('drugDeal.common.services') 
      }
    },
    computed:{
        lang(){
          // debugger    
          return i18n.locale
        },
        ...mapGetters({
          navbar:'layout/navbar'
        })
    },
    created(){
      document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('drugDeal.common.services') 
    },
    mounted(){
      // console.log('in mounted service page')
        this.setHeaderBg('bg-Mulberry')
        this.setNavbar('services')
        this.setFooter(true)
        // console.log('navbar service page',this.navbar)
    }
}
