
// import {i18n} from '@/i18n';
import { mapGetters, mapActions } from 'vuex';
export default {
    data(){
        return{
            noCurrentUser:false,
            item:""
        }
    },
    name:'app-services-items',
    computed:{
        ...mapGetters({
            currentUser: 'auth/currentUser',
            is_screen_sm: 'layout/is_screen_sm'
        })
    },
    methods:{
        ...mapActions({
            setHeaderBg:'layout/setHeaderBg',
        }),
        scrollHandler(scroll){
            if(this.noCurrentUser && scroll.position == 0){
                this.setHeaderBg('bg-shadow')               
            }
        },
        login(){
            this.noCurrentUser = !this.noCurrentUser
            this.$emit('openLoginDialog')
        },
        doGoToDrugIndex(){
            const lang = localStorage.getItem('language') || 'en'            
            this.$router.push(`/${lang}/drug-index`)
        },
        doGoToPrescriptions(){
            // console.log('userrrr',this.currentUser)
            if(!this.currentUser 
            // || !this.currentUser.emailVerified
            ){
                this.noCurrentUser = true
                this.item = this.i18n('drugDeal.services.prescriptions')
            }
            else{
                // const lang = localStorage.getItem('language') || 'en'
                // this.$router.push(`/${lang}/prescriptions`)
                const lang = localStorage.getItem('language') || 'en'
                this.$router.push({
                    name: 'prescriptions',
                    params:{
                        lang
                    },
                    query:{
                        page: 1
                    }
                })
            }
            
        },
        doGoToJobOpportunities(){
            if(!this.currentUser 
            // || !this.currentUser.emailVerified
            ){
                this.noCurrentUser = true
                this.item = this.i18n('drugDeal.services.jobs')
            }
            else{
                const lang = localStorage.getItem('language') || 'en'
                // this.$router.push(`/${lang}/job-opportunities`)
                this.$router.push({
                    name: 'job-opportunities',
                    params:{
                        lang
                    },
                    query:{
                        page: 1
                    }
                })
            }
        },
        doGoToFacilities(){
            if(!this.currentUser 
            // || !this.currentUser.emailVerified
            ){
                this.noCurrentUser = true
                this.item = this.i18n('drugDeal.services.facilities')
            }
            else{
                // const lang = localStorage.getItem('language') || 'en'
                // this.$router.push(`/${lang}/facilities`)
                const lang = localStorage.getItem('language') || 'en'
                this.$router.push({
                    name: 'facilities',
                    params:{
                        lang
                    },
                    query:{
                        page: 1
                    }
                })
            }
            
        },
        doGoToOffers(){
            // this.$router.push('offers')
             if(!this.currentUser 
            //  || !this.currentUser.emailVerified
             ){
                this.noCurrentUser = true
                this.item = this.i18n('drugDeal.services.offers')
            }
            else{
                const lang = localStorage.getItem('language') || 'en'
                // this.$router.push(`/${lang}/offers`)
                this.$router.push({
                    name: 'offers',
                    params:{
                        lang
                    },
                    query:{
                        page: 1
                    }
                })
                // this.$router.push(`/${lang}/drug-index`)
            }

        },
        i18n(key, args) {
            return this.$t(key, args);
        },
    }
}
