import { render, staticRenderFns } from "./services-items.vue?vue&type=template&id=4e9fefd8&"
import script from "./services-items.vue?vue&type=script&lang=js&"
export * from "./services-items.vue?vue&type=script&lang=js&"
import style0 from "./services-items.vue?vue&type=style&index=0&id=4e9fefd8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QScrollObserver,QCard,QCardSection,QImg,QDialog,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QScrollObserver,QCard,QCardSection,QImg,QDialog,QBtn})
